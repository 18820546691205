@tailwind base;
@tailwind components;
@tailwind utilities;

[contenteditable="true"] {
  white-space: pre-wrap;
  word-break: break-all;
}

/* box-border를 글로벌하게 설정 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* position */
@layer components {
  .resizable {
    @apply h-0 pt-[100%] relative;
  }
  .resizable > div {
    @apply absolute top-0 left-0 w-full h-full;
  }
  .flex-center {
    @apply flex justify-center items-center;
  }
  .flex-col-center {
    @apply flex flex-col justify-center items-center;
  }
  .flex-left {
    @apply flex justify-start items-center;
  }
  .flex-right {
    @apply flex justify-end items-center;
  }
  .flex-between {
    @apply flex justify-between items-center;
  }
}

/* cash-table */
@layer components {
  .cash-table {
    @apply text-left;
  }
  .cash-table .row {
    @apply flex w-full text-sm border-l;
  }
  .cash-table .cell {
    @apply px-4 py-3 shrink-0 border-r border-b w-[120px] bg-white;
  }
  .cash-table .cell-lg {
    @apply px-4 py-3 shrink-0 border-r border-b  w-[170px] bg-white;
  }
  .cash-table .cell-last {
    @apply flex-center py-3 border-r border-b bg-white flex-grow  min-w-[60px];
  }
  .cash-table .heads {
    @apply flex w-full text-xs border-l font-semibold text-stone-400;
  }
  .cash-table .heads .cell {
    @apply border-t text-center;
  }
  .cash-table .heads .cell-lg {
    @apply border-t text-center;
  }
  .cash-table .heads .cell-last {
    @apply border-t flex-grow min-w-[60px];
  }
}

/* table */
@layer components {
  .table .head {
    @apply border-b p-4;
  }
}

/* post */
@layer components {
  .post {
    @apply flex flex-col text-base sm:text-lg text-stone-700 break-all py-4;
  }
  .post div {
    @apply outline-none;
  }
  .post p {
    @apply outline-none my-4;
  }
  .post .code {
    @apply outline-none text-sm relative;
  }
  .post h1 {
    @apply outline-none font-bold text-4xl my-8 border-b border-black pb-4;
  }
  .post h2 {
    @apply outline-none my-8 font-bold text-xl sm:text-3xl text-stone-900;
  }
  .post h3 {
    @apply outline-none my-8 text-xl sm:text-2xl text-stone-900;
  }
  .post h4 {
    @apply outline-none my-4 font-bold text-lg sm:text-xl text-stone-900;
  }
  .post h5 {
    @apply outline-none my-4 font-bold text-lg text-stone-900;
  }
  .post h6 {
    @apply outline-none my-4 font-bold text-base text-stone-900;
  }
  .post ul {
    @apply outline-none pl-8 text-stone-900;
  }
  .post ul > li {
    @apply outline-none pl-2 list-disc;
  }
  .post ol {
    @apply outline-none pl-8 text-stone-900;
  }
  .post ol > li {
    @apply outline-none pl-2 list-decimal;
  }
  .post hr {
    border: none;
    height: 1px;
    background-color: black;
  }
  .post a {
    @apply text-indigo-500 hover:underline;
  }
  .post button {
    @apply bg-indigo-500 my-8 block mx-auto  min-w-[300px] text-white font-bold text-lg px-8 py-3 rounded-full border-2 border-indigo-800 hover:bg-indigo-600;
  }
  .post img {
    @apply my-4;
  }
  .post blockquote {
    @apply outline-none px-5 bg-stone-100 rounded-lg text-base flex flex-col space-y-1 border border-stone-200;
  }
  .post #toc {
    @apply border border-black text-sm p-4 max-w-[300px] rounded;
  }
  .post #toc > .toc-title {
    @apply font-bold mb-2 text-base;
  }
  .post #toc > ul {
    @apply pl-0;
  }
  .post #toc > ul > li {
    @apply list-none pl-0 py-1;
  }
  .post #toc > ul > li.h2 {
    @apply pl-4;
  }
  .post #toc > ul > li.h3 {
    @apply pl-8;
  }
  .post #toc > ul > li.h4 {
    @apply pl-12;
  }
}
